import * as React from 'react';

import { addMaxWidthToImageLocation } from '../helpers/imageHelpers';

interface RenderAssetProps {
  maxWidth: number;
  isVideo?: boolean;
  title?: string;
  assetLocation?: string;
  loading?: 'lazy' | 'eager';
}

export const RenderAsset: React.FC<RenderAssetProps> = ({
  maxWidth,
  isVideo,
  title,
  assetLocation,
  loading = 'lazy',
}) => {
  const imageUri = addMaxWidthToImageLocation(assetLocation, maxWidth);
  const placeholderUri = addMaxWidthToImageLocation(assetLocation, 5);

  const [src, setSrc] = React.useState(placeholderUri);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isInView, setIsInView] = React.useState(false);
  const imgRef = React.useRef<HTMLImageElement | null>(null);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
        }
      },
      { threshold: 0.1 } // Trigger when at least 10% of the element is in view
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, []);

  React.useEffect(() => {
    if (isVideo) return;

    if (isInView && !isLoaded) {
      const img = new Image();
      img.src = imageUri;
      img.onload = () => {
        setTimeout(() => {
          setSrc(imageUri);
          setIsLoaded(true);
        }, 400); // Smooth transition delay
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this effect when isInView changes
  }, [isInView]);

  if (isVideo) {
    const posterAssetLocation = `${assetLocation?.substring(0, assetLocation.lastIndexOf('/'))}/posterImage0.png`;
    return (
      <video
        onContextMenu={e => e.preventDefault()} // Disables right-click on video
        poster={posterAssetLocation}
        muted
        controls
        loop
        playsInline
        src={assetLocation}
        preload={'metadata'}
      >
        <track kind="captions" />
      </video>
    );
  }

  return (
    <img
      ref={imgRef}
      onContextMenu={e => e.preventDefault()}
      src={src}
      alt={`${title} Preview`}
      loading={loading}
      style={{
        minHeight: '100px',
        transition: 'opacity 0.3s ease-in-out', // Smooth opacity transition
        opacity: isLoaded || src === placeholderUri ? 1 : 0.5, // Dim the image while loading
      }}
      // eslint-disable-next-line react/no-unknown-property -- this is a valid attribute
      fetchpriority={loading === 'eager' ? 'high' : 'auto'}
    />
  );
};
