import * as React from 'react';

import { apiHooks } from '../../api';
import { Masonry } from '../gallery/masonry';
import { GalleryAsset } from '../galleryAsset';

import styles from './homepageGallery.module.scss';

export const HomepageGallery: React.FC = () => {
  const [{ data }] = apiHooks.ark.arkAssetSearchPost.useQuery({
    parameters: {
      assetSearchQuery: { isHomepageWorthy: true, jitter: true, hasPromotionLink: null, from: 0, size: 20 },
    },
  });

  return (
    <div className={styles.homepageGalleryContainer}>
      <div className={styles.inner}>
        <h3>Browse our Gallery</h3>

        <Masonry
          data={data?.data.results ?? []}
          breakpoints={{
            500: 1,
            800: 2,
            1300: 3,
            1600: 4,
          }}
          renderItem={(asset, colAssetIndex) => (
            <GalleryAsset key={asset.assetId} maxWidth={500} asset={asset} mode="home" lazyLoad={colAssetIndex > 1} />
          )}
        />
      </div>
    </div>
  );
};
