import { Spinner } from '@rocketmakers/armstrong';
import { ClassificationListProperty } from '@wildscreen/api/src/apiClients';
import { routes, TClassificationProperty } from '@wildscreen/core/src/core';
import { useIsMobile } from '@wildscreen/core/src/hooks/useIsMobile';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { apiHooks } from '../../api';
import { BackButton } from '../../components/backButton';
import { CollectionTile } from '../../components/collectionTile';
import { useShowSearch } from '../../contexts/header';
import { getTitle } from '../../helpers/metaHelpers';

import defaultTile from '@wildscreen/ui-components/src/assets/pngs/defaultTile.png';

import styles from './classificationTag.module.scss';

export const ClassificationTagView: React.FC = () => {
  const { targetProperty } = useParams<TClassificationProperty>();
  const isMobile = useIsMobile();
  useShowSearch();
  const [{ data, isFetching }] = apiHooks.data.dataPropertiesClassificationTargetPropertyGet.useQuery({
    parameters: {
      targetProperty: targetProperty as ClassificationListProperty,
    },
    cacheKey: p => `${p?.targetProperty}`,
  });

  const splitTargetProperty = targetProperty?.split(/(?=[A-Z])/).join(' ') ?? '';

  return (
    <>
      <Helmet>
        <title>{getTitle(splitTargetProperty)}</title>
        <meta name="description" content={`Explore ${splitTargetProperty} on Wildscreen ARK`} />
      </Helmet>
      <div className={styles.container}>
        {!isMobile && <BackButton />}
        <div className={styles.headings}>
          <h2>{splitTargetProperty}</h2>
        </div>
        <div className={styles.gridContainer}>
          <div className={styles.grid}>
            {isFetching ? (
              <Spinner />
            ) : (
              data?.data?.tags?.map(tag => {
                let coverImage = defaultTile;
                if (tag.coverImageLocation && tag.coverImageLocation !== '') {
                  coverImage = tag.coverImageLocation;
                }

                return (
                  <CollectionTile
                    key={tag.name}
                    size="large"
                    to={routes.authenticated.arkive.exploreClassification({
                      classificationProperty: targetProperty,
                      classificationSearchTerm: tag.name,
                    })}
                    coverImage={coverImage}
                    title={tag.name ?? ''}
                    isLoading={isFetching}
                  />
                );
              })
            )}
          </div>
        </div>
      </div>
    </>
  );
};
