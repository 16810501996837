import { ApiHooksStore } from '@rocketmakers/api-hooks';
import { ToastProvider } from '@rocketmakers/armstrong';
import * as Sentry from '@sentry/react';
import { LanguageContextProvider } from '@wildscreen/core/src/context/dictionary';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { AppContextProvider } from './contexts/appContext';
import { CookiePreferencesContextProvider } from './contexts/cookiePreferences';
import { GoogleAnalyticsContextProvider } from './contexts/googleAnalytics';
import { HeaderContextProvider } from './contexts/header';
import { Routing } from './routing';
import { Maintenance } from './views/maintenance';

import '@rocketmakers/armstrong/css';
import './theme/theme.scss';

Sentry.init({
  environment: APP_CONFIG.environment,
  dsn: APP_CONFIG.arkiveSentryDsn,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracePropagationTargets: [],
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.1,
});

async function render() {
  const rootElementId = 'root';
  const container = document.getElementById(rootElementId);
  if (!container) {
    throw new Error(`Root element ${rootElementId} not found in page`);
  }
  const root = createRoot(container);
  const maintenanceMode = !!APP_CONFIG.maintenanceMode;

  if (maintenanceMode) {
    root.render(<Maintenance />);
  } else {
    root.render(
      <BrowserRouter>
        <ToastProvider>
          <GoogleAnalyticsContextProvider>
            <CookiePreferencesContextProvider>
              <ApiHooksStore.Provider>
                <HeaderContextProvider>
                  <AppContextProvider>
                    <LanguageContextProvider>
                      <Routing />
                    </LanguageContextProvider>
                  </AppContextProvider>
                </HeaderContextProvider>
              </ApiHooksStore.Provider>
            </CookiePreferencesContextProvider>
          </GoogleAnalyticsContextProvider>
        </ToastProvider>
      </BrowserRouter>
    );
  }
}

render();
