import { AssetListProperty, ClassificationListProperty } from '@wildscreen/api/src/apiClients';
import { useDictionary } from '@wildscreen/core/src/core';
import { NoResults } from '@wildscreen/ui-components/src/components/noResults/noResults';
import * as React from 'react';

import { apiHooks } from '../../api';
import { mapTargetPropertyForQuery } from '../../helpers/targetProperty';
import { Masonry } from '../gallery/masonry';
import { GalleryAsset } from '../galleryAsset';

import styles from './shared.module.scss';

export interface IGallery {
  searchTerm?: string;
  targetProperty?: ClassificationListProperty | AssetListProperty;
  maxImageWidth: number;
}

export const Gallery: React.FC<IGallery> = ({ searchTerm, targetProperty, maxImageWidth }) => {
  const { general } = useDictionary('en');
  const [fetchAssets, { data, isFetching }] = apiHooks.ark.arkAssetSearchPost.useMutation();

  React.useEffect(() => {
    if (searchTerm && targetProperty) {
      fetchAssets({
        assetSearchQuery: {
          isHomepageWorthy: null,
          hasPromotionLink: null,
          from: 0,
          jitter: true,
          size: 48,
          [mapTargetPropertyForQuery(targetProperty) as string]: searchTerm,
        },
      });
    }
  }, [searchTerm, fetchAssets, targetProperty]);

  const noResults = data?.data.results?.length === 0 && !isFetching;
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{general.gallery}</h3>
      {noResults ? (
        <NoResults />
      ) : (
        <Masonry
          data={data?.data?.results || undefined}
          renderItem={asset => (
            <GalleryAsset maxWidth={maxImageWidth} key={asset.assetId} mode="gallery" asset={asset} />
          )}
          breakpoints={{
            500: 1,
            800: 2,
            1300: 3,
            1600: 4,
          }}
        />
      )}
    </div>
  );
};
