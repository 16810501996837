import { ArrowLeft } from '@phosphor-icons/react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './backButton.module.scss';

export const BackButton: React.FC = () => {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => {
        if (window.history.length > 1) {
          navigate(-1);
        } else {
          navigate('/');
        }
      }}
      className={styles.backButtonContainer}
      tabIndex={0}
    >
      <ArrowLeft /> <div className={styles.back}>back</div>
    </button>
  );
};
