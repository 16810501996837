import * as React from 'react';

import styles from './tabController.module.scss';

export interface ITabControllerProps {
  related?: React.ReactNode;
  gallery?: React.ReactNode;
}

export const TabController: React.FC<ITabControllerProps> = ({ related, gallery }) => {
  const [activeTab, setActiveTab] = React.useState<'related' | 'gallery'>('related');

  return (
    <div className={styles.container}>
      <div className={styles.tabContainer}>
        <button
          data-tab-active={activeTab === 'related'}
          className={styles.tabItem}
          onClick={() => setActiveTab('related')}
        >
          Related
        </button>
        <button
          data-tab-active={activeTab === 'gallery'}
          className={styles.tabItem}
          onClick={() => setActiveTab('gallery')}
        >
          Gallery
        </button>
      </div>
      <div className={styles.tabControllerContent}>{activeTab === 'related' ? related : gallery}</div>
    </div>
  );
};
