import { AssetSearchQuery, SearchAsset, SearchClassification, TaxonRank } from '@wildscreen/api/src/apiClients';
import { useDictionary } from '@wildscreen/core/src/core';
import { LoadMoreButton } from '@wildscreen/ui-components/src/components/loadMoreButton/loadMoreButton';
import * as React from 'react';

import { apiHooks } from '../../api';
import { GalleryAsset } from '../galleryAsset';
import { Masonry } from './masonry';

import styles from './gallery.module.scss';

export type TCurrentTaxonData = {
  currentTaxonRank: string;
  currentTaxonValue: string;
};

export interface IGalleryProps {
  wildscreenId?: string;
  searchData?: SearchClassification;
  overrideTaxonData?: TCurrentTaxonData;
  maxImageWidth: number;
}

const maxNumberOfAssets = 10;

export const Gallery: React.FC<IGalleryProps> = ({ wildscreenId, searchData, overrideTaxonData, maxImageWidth }) => {
  const { general } = useDictionary('en');
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [canFetchMore, setCanFetchMore] = React.useState<boolean>(true);
  const [assets, setAssets] = React.useState<Array<SearchAsset>>([]);
  const [isFetching, setIsFetching] = React.useState<boolean>(false);
  const [totalAssets, setTotalAssets] = React.useState<number>(0);

  const fetchAssets = apiHooks.ark.arkAssetSearchPost.useRequest();

  const currentTaxonData = React.useMemo<TCurrentTaxonData>(() => {
    if (overrideTaxonData) {
      return overrideTaxonData;
    }
    return {
      currentTaxonRank: searchData?.taxonRank || '',
      currentTaxonValue: searchData?.[searchData?.taxonRank?.toLowerCase() as string],
    };
  }, [searchData, overrideTaxonData]);

  const loadMore = React.useCallback(
    async (pageIndex: number) => {
      setIsFetching(true);
      const from = pageIndex * maxNumberOfAssets;
      let assetSearchQuery: AssetSearchQuery = {
        jitter: true,
        from,
        size: maxNumberOfAssets,
        hasPromotionLink: null,
        isHomepageWorthy: null,
      };

      if (currentTaxonData?.currentTaxonRank === TaxonRank.Species && !overrideTaxonData) {
        assetSearchQuery = {
          ...assetSearchQuery,
          wildscreenId,
        };
      } else if (overrideTaxonData) {
        assetSearchQuery = {
          ...assetSearchQuery,
          [currentTaxonData?.currentTaxonRank as string]: currentTaxonData?.currentTaxonValue,
          search: currentTaxonData.currentTaxonValue,
        };
      } else {
        assetSearchQuery = {
          ...assetSearchQuery,
          [currentTaxonData?.currentTaxonRank as string]: currentTaxonData?.currentTaxonValue,
        };
      }

      const responseData = await fetchAssets({ assetSearchQuery: { ...assetSearchQuery, from } });
      if (totalAssets === 0) {
        setTotalAssets(responseData.data.total);
      }

      if (responseData?.data?.results) {
        // This stops inserting existing records into the array
        setAssets(s => {
          // Extract assetIds from responseData.data.results
          const existingIds = s.map(item => item.assetId);

          // Filter out existing records based on assetId
          const filteredAssets =
            responseData?.data?.results?.filter(newItem => !existingIds.includes(newItem.assetId)) || [];

          const mergedArray = [...s, ...filteredAssets];
          // Merge the filtered array with responseData.data.results
          // This is to stop retrieving extra data from the API
          const remainder = responseData.data.total - mergedArray.length;
          setCanFetchMore(remainder > 0);
          return mergedArray;
        });
      }

      setIsFetching(false);
    },
    [
      currentTaxonData?.currentTaxonRank,
      currentTaxonData?.currentTaxonValue,
      fetchAssets,
      overrideTaxonData,
      totalAssets,
      wildscreenId,
    ]
  );

  React.useEffect(() => {
    loadMore(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- go away
  }, [currentPage]);

  if (assets?.length === 0 && !isFetching) {
    return <div className={styles.noResults}>{general.noResultsFound}</div>;
  }

  return (
    <>
      <Masonry
        data={assets}
        breakpoints={{
          1000: 1,
          1600: 2,
          1800: 3,
        }}
        renderItem={asset => <GalleryAsset maxWidth={maxImageWidth} key={asset.assetId} mode="gallery" asset={asset} />}
      />
      {canFetchMore && <LoadMoreButton isFetching={isFetching} onClick={() => setCurrentPage(s => s + 1)} />}
    </>
  );
};
