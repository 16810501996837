import * as React from 'react';

import styles from './masonry.module.scss';

const defaultBreakpoints = {
  500: 1,
  800: 2,
  1300: 3,
  1600: 4,
};

function splitArrayIntoChunks<T>(array: T[], x: number): T[][] {
  if (x <= 0) throw new Error('Number of chunks (x) must be greater than 0.');

  const result: T[][] = Array.from({ length: x }, () => []); // Initialize x empty arrays.

  array.forEach((item, index) => {
    result[index % x].push(item); // Distribute items cyclically across the arrays.
  });

  return result;
}

interface IProps<T> {
  breakpoints?: Record<number, number>;
  data?: T[];
  gap?: string;
  renderItem: (item: T, colAssetIndex: number) => React.ReactNode;
}

export function Masonry<T>({ breakpoints = defaultBreakpoints, data = [], renderItem, gap = '0.8rem' }: IProps<T>) {
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const columns = React.useMemo<number>(() => {
    const closestBreakpoint = Object.keys(breakpoints).find(key => screenWidth < parseInt(key, 10));
    const lastBreakpoint = Object.keys(breakpoints).pop();
    if (!closestBreakpoint) return lastBreakpoint ? breakpoints[+lastBreakpoint] : 4;

    return breakpoints[+closestBreakpoint];
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only on screen width
  }, [screenWidth]);

  const dataColumns = React.useMemo(() => splitArrayIntoChunks(data || [], columns), [data, columns]);

  return (
    <div
      className={styles.grid}
      style={{
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
        columnGap: gap,
      }}
    >
      {dataColumns.map((column, i) => (
        <div key={i} className={styles.col} style={{ gap }}>
          {column.map((asset, colAssetIndex) => renderItem(asset, colAssetIndex))}
        </div>
      ))}
    </div>
  );
}
